<template>
  <el-main>
    <div class="main_box">
      <div class="left">
        <div class="banner">
          <img :src="imgUrlHeadR + 'upRenewBanner.png'" alt="" />
          <div class="bannerText">彩蛋数字 国内领先的数字营销服务商</div>
        </div>
        <table class="dataBox" border="1" cellspacing="0">
          <thead>
            <tr>
              <!-- maxsubaccount -->
              <td>智慧客服坐席数</td>
              <!-- maxaccount -->
              <td>员工账户个数</td>
              <!-- capacity -->
              <td>SSD容量存储空间</td>
              <!-- max_cashier_subaccount -->
              <td>收银账户坐席</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ activeEdition.maxsubaccount || nowEdition.maxsubaccount }}</td>
              <td>{{ activeEdition.maxaccount || nowEdition.maxaccount }}</td>
              <td>{{ activeEdition.capacity || nowEdition.capacity }}</td>
              <td>{{ activeEdition.max_cashier_subaccount || nowEdition.max_cashier_subaccount }}</td>
            </tr>
          </tbody>
        </table>
        <!-- 展示介绍 -->
        <div class="showList">
          <div class="listItem" v-for="(item, index) in introduceList" :key="index">
            <div class="title">{{ item.name }}</div>
            <div class="content" v-if="item.name !== '智能营销功能'">{{ item.content }}</div>
            <div class="content" v-else>{{ item.content }}{{ activeEdition.id ? activeEdition.use_package_txt : nowEdition.use_package_txt }}</div>
            <img :src="imgUrlHeadR + item.img" alt="" />
          </div>
        </div>
        <div class="tips">彩蛋数字助力企业数字营销一体化解决方案</div>
      </div>
      <div class="right">
        <div class="rightTitle">订购彩蛋数字系统</div>
        <div class="tabsBox">
          <div @click="changeUpOrRenew(1)" :class="upOrRenew === 1 ? 'active' : ''">升级版本</div>
          <div @click="changeUpOrRenew(2)" :class="upOrRenew === 2 ? 'active' : ''">续费套餐</div>
        </div>
        <div class="title" style="margin: 24px 0">当前版本</div>
        <div class="nowEdition">{{ nowEdition.name }}</div>
        <div class="title" style="margin-bottom: 20px" v-if="upOrRenew === 1">
          选择升级的版本
          <span @click="lookOther">查看版本区别</span>
        </div>
        <!-- 展示可以升级的版本信息 -->
        <div class="editionList" v-if="upOrRenew === 1">
          <div :class="['listItem', activeEdition.id === item.id ? 'active' : '']" @click="chooseEdition(item)" v-for="(item, index) in editionInfo" :key="index">
            <div class="editionName">
              {{ item.name }}
              <div class="isRecommend" v-if="item.is_recommend" :style="{ background: `url(${imgUrlHeadR}liao.png)` }">
                <img :src="imgUrlHeadR + 'quan.png'" alt="" />
                <div>推荐</div>
              </div>
            </div>
            <div style="font-size: 12px">
              <del>
                ￥
                <span>{{ item.price }}</span>
                /年
              </del>
            </div>
          </div>
        </div>
        <!-- 展示当前版本信息 -->
        <div class="title" v-if="upOrRenew === 2" style="margin: 10px 0">版本信息</div>
        <div class="infoDetail" v-if="upOrRenew === 2">
          <div class="detailItem">
            <span>站点名称：</span>
            <span>{{ shopInfo.name }}</span>
          </div>
          <div class="detailItem">
            <span>开通时间：</span>
            <span>{{ getDateformat(start_time) }}</span>
          </div>
          <div class="detailItem">
            <span>到期时间：</span>
            <span>{{ getDateformat(siteEndTime) }}</span>
          </div>
          <div class="detailItem">
            <span>剩余天数：</span>
            <span>{{ Math.floor((siteEndTime - Math.floor(new Date().getTime() / 1000)) / 60 / 60 / 24) + '天' }}</span>
          </div>
        </div>
        <div class="title" v-if="upOrRenew === 2" style="margin: 24px 0">续费信息</div>
        <div class="timeList" v-if="upOrRenew === 2">
          <div @click="() => (chooseRenew = item)" :class="[chooseRenew.id === item.id ? 'active' : '']" v-for="(item, index) in renew_meal" :key="index">{{ item.name }}</div>
        </div>
        <div class="title" style="margin: 40px 0 20px 0">选择支付方式</div>
        <div class="payStyle">
          <div
            :class="[activePayStyle.status === item.status ? 'active' : '']"
            @click="choosePayStyle(item)"
            :style="{ '--bgm': `url(${imgUrlHeadR}active.png)` }"
            v-for="(item, index) in payStyle"
            :key="index"
          >
            <img :src="imgUrlHeadR + item.img" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="showPrice">
          <span>{{ upOrRenew === 1 ? '需补差价' : '价格' }}</span>
          <span v-if="upOrRenew === 1" style="font-size: 24px; color: #fa0000; font-weight: blod">￥{{ activeEdition.id ? parseFloat(activeEdition.price - nowEdition.price).toFixed(2) : '' }}</span>
          <span v-if="upOrRenew === 2" style="font-size: 24px; color: #fa0000; font-weight: blod">￥{{ chooseRenew.price }}</span>
        </div>
        <div class="payButton" @click="btnPay">提交确认</div>
      </div>
    </div>
    <el-dialog class="paymentDialog" :title="activePayStyle.status == 1 ? '微信支付' : '支付宝支付'" center :visible.sync="is_showPay" :before-close="handleClose" width="30%">
      <div class="content">
        <div class="qrcode">
          <div id="qrcode" ref="qrcode"></div>
          <div class="success" v-show="is_success">
            <i class="el-icon-check"></i>
          </div>
        </div>
        <div class="money">
          <p>支付金额：{{ upOrRenew === 1 ? parseFloat(activeEdition.price - nowEdition.price).toFixed(2) : chooseRenew.price }}元</p>
          <p>请使用{{ activePayStyle.status == 1 ? '微信' : '支付宝' }}扫一扫，扫描二维码进行支付</p>
        </div>
        <div class="tips">
          <p>支付完成前请不要关闭此窗口</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config.js';
import { getDateformat } from '@/util/getDate';
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      getDateformat,
      // 升级还是续费 1-升级 2-续费
      upOrRenew: 1,
      noChange: true,
      imgUrlHeadR: imgUrlHead + 'upRenew/',
      nowEdition: {},
      introduceList: [
        { name: '聚合流量曝光引流', content: '微信、抖音、支付宝、百度、华为服务号、快应用联盟等多个平台，一键开店，统一管理，全方位引流', img: 'sy_1.png' },
        { name: '智能营销功能', content: '多种智能营销模式玩法，', img: 'sy_2.png' },
        {
          name: '会员管理',
          content: '通过用户类型、状态、身份、性别等快速筛选会员用户输入用户姓名或编号即可快速精确查找指定用户可在后台给会员批量发送优惠券、赠送余额增加会员等级功能，可在后台自由编辑会员体系。',
          img: 'sy_3.png',
        },
        {
          name: '数据统计',
          content:
            '可直观查看商品订单、交易总额、积分使用情况等通过统计各个商品的交易数量，分析商品价值及活动情况详细的财务报表统计，收入支出数据一目了然统计产品销量排行，更精准把握高销量商品，合理分配进货',
          img: 'sy_4.png',
        },
        {
          name: '智慧客服',
          content:
            '可接入微信公众号、小程序、H5，多渠道部署，实现客服统一回复和处理，以客户为中心的视角，提供一致的客服体验。可支持文字、图片、语音、商品、订单、二维码等对话方式，满足用户和客服之间多样化沟通需求。提供客服知识问答库、快捷回复库，高效客服工作台，大幅提升客户满意度',
          img: 'sy_5.png',
        },
        { name: '智慧收银台', content: '支持人工收银、自动收银，全自动化收银流程，省去排队的烦恼，让你一步到位，从此告别排队结账，顾客当然更爱来。', img: 'sy_6.png' },
      ],
      editionInfo: [],
      InfoUrl: '',
      // 当前选中的版本
      activeEdition: {},
      payStyle: [
        { status: 2, name: '支付宝支付', img: 'alipay.png' },
        { status: 1, name: '微信支付', img: 'wxpay.png' },
      ],
      // 当前选中的支付方式
      activePayStyle: { status: 2, name: '支付宝支付', img: 'alipay.png' },
      is_showPay: false,
      timer: null,
      // 支付订单id
      order_id: null,
      chooseRenew: {},
      renew_meal: [],
      // 站点基本信息
      start_time: localStorage.getItem('uniac_startTime'),
    };
  },
  created() {
    this.isNeedUpOrRenew();
  },
  computed: {
    // 用户的店铺信息
    shopInfo() {
      return this.$store.getters.shopInfo;
    },
    siteEndTime() {
      return this.$store.getters.siteEndTime;
    },
  },
  methods: {
    changeUpOrRenew(status) {
      if (this.noChange) {
        this.upOrRenew = status;
      }
    },
    handleClose() {
      this.is_showPay = !1;
    },
    isNeedUpOrRenew() {
      this.$axios.post(this.$api.admin.upRenew.upgrade).then(res => {
        if (res.code === 0) {
          // 获取查看版本区别的外部链接
          this.InfoUrl = res.result.difference_version;
          // 获取续费的年限信息
          this.renew_meal = res.result.renew_meal;
          // 初始化选中的年限信息
          this.chooseRenew = this.renew_meal[0];
          // 获取当前自己的版本信息
          this.$set(res.result.meal, 'use_package_txt', '');
          if (res.result.meal.name !== '免费版') {
            res.result.meal.use_package.map(item => {
              res.result.meal.use_package_txt += item.name + ',';
            });
          }
          res.result.meal.use_package_txt = res.result.meal.use_package_txt.substring(0, res.result.meal.use_package_txt.length - 1);
          this.nowEdition = res.result.meal;
          // 处理可升级版本的数据 整合分销功能列表
          if (res.result.upgrade_meal.length) {
            res.result.upgrade_meal.map(item => {
              if (item.use_package) {
                this.$set(item, 'use_package_txt', '');
                item.use_package.map(item1 => {
                  item.use_package_txt += item1.name + ',';
                });
              }
              item.use_package_txt = item.use_package_txt.substring(0, item.use_package_txt.length - 1);
            });
            // 获取还可以升级的版本信息
            this.editionInfo = res.result.upgrade_meal;
            // 不需要初始选中状态
            // this.activeEdition = this.editionInfo[0];
            console.log(this.editionInfo);
          } else {
            this.noChange = false;
            this.upOrRenew = 2;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    chooseEdition(val) {
      if (this.activeEdition.id === val.id) {
        this.activeEdition = {};
      } else {
        this.activeEdition = val;
      }
    },
    choosePayStyle(val) {
      this.activePayStyle = val;
    },
    // 点击支付按钮
    btnPay() {
      if (this.upOrRenew === 1) {
        if (!this.activeEdition.id) {
          this.$message.warning('请选择升级版本');
          return;
        }
        this.$axios
          .post(this.$api.admin.upRenew.getPreOrder, {
            uniacid: localStorage.getItem('u_id'),
            meal_id: this.activeEdition.id,
          })
          .then(res => {
            if (res.code === 0) {
              this.order_id = res.result.order_id;
              return res.result.order_id;
            } else {
              this.$message.error(res.msg);
            }
          })
          .then(res1 => {
            if (res1) {
              return this.$axios.post(this.$api.admin.upRenew.upgradePay, {
                order_id: res1,
                pay_type: this.activePayStyle.status,
              });
            }
          })
          .then(res2 => {
            if (res2.code === 0) {
              this.is_showPay = !0;
              setTimeout(() => {
                this.$refs.qrcode.innerHTML = '';
                let qr_code = '';
                if (this.activePayStyle.status == 1) {
                  qr_code = res2.result;
                } else {
                  qr_code = res2.result.qr_code;
                }
                let qrcode = new QRCode('qrcode', {
                  width: 132,
                  height: 132,
                  text: qr_code, // 二维码地址
                  colorDark: '#000',
                  colorLight: '#fff',
                });
              }, 100);
              this.getOrderStatus(1);
            } else {
              this.$message.error(res2.msg);
            }
          });
      } else {
        this.$axios
          .post(this.$api.site.getPreOrder, {
            uniacid: localStorage.getItem('u_id'),
            renew_meal_id: this.chooseRenew.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.order_id = res.result.order_id;
              return res.result.order_id;
            } else {
              this.$message.error(res.msg);
            }
          })
          .then(res1 => {
            if (res1) {
              return this.$axios.post(this.$api.site.renewPay, {
                pay_type: this.activePayStyle.status,
                order_id: this.order_id,
              });
            }
          })
          .then(res2 => {
            if (res2.code === 0) {
              this.is_showPay = !0;
              setTimeout(() => {
                this.$refs.qrcode.innerHTML = '';
                let qr_code = '';
                if (this.activePayStyle.status == 1) {
                  qr_code = res2.result;
                } else {
                  qr_code = res2.result.qr_code;
                }
                let qrcode = new QRCode('qrcode', {
                  width: 132,
                  height: 132,
                  text: qr_code, // 二维码地址
                  colorDark: '#000',
                  colorLight: '#fff',
                });
              }, 100);
              this.getOrderStatus(2);
            } else {
              this.$message.error(res2.msg);
            }
          });
      }
    },
    // 查询支付订单状态 1-升级订单 2-续费订单
    getOrderStatus(status) {
      this.$axios
        .post(status === 1 ? this.$api.admin.upRenew.orderFind : this.$api.user.orderFind, {
          order_id: this.order_id,
          pay_type: this.activePayStyle.status,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('支付成功');
            this.is_showPay = false;
            this.isNeedUpOrRenew();
            this.$store.dispatch('user/getShopInfo');
          } else {
            if (this.is_showPay) {
              this.timer = setTimeout(() => {
                this.getOrderStatus();
              }, 1000);
            }
          }
        });
    },
    lookOther() {
      window.open(this.InfoUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 0;
  height: 100%;
  background-color: #fff;
  .main_box {
    display: flex;
    height: 100%;
    .left,
    .right {
      height: 100%;
      background-color: #fff;
    }
    .left {
      flex: 1;
      padding: 13px 24px 0 24px;
      .banner {
        position: relative;
        margin-bottom: 17px;
        img {
          width: 100%;
        }
        .bannerText {
          position: absolute;
          left: 200px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .dataBox {
        width: 100%;
        line-height: 50px;
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        border-color: #ededed;
        margin-bottom: 17px;
        thead {
          background-color: #f8f9fa;
        }
        td {
          text-align: center;
        }
      }
      .showList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .listItem {
          width: 49%;
          height: 165px;
          box-shadow: 0px 2px 9px 0px rgba(28, 28, 28, 0.09);
          padding-top: 20px;
          padding-left: 25px;
          margin-bottom: 10px;
          position: relative;
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #1a1a1a;
            margin-bottom: 18px;
          }
          .content {
            padding-right: 60px;
            font-size: 14px;
            color: #646566;
            line-height: 24px;
          }
          img {
            position: absolute;
            bottom: 0;
            right: 0;
            user-select: none;
          }
        }
      }
      .tips {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        color: #999999;
        line-height: 24px;
      }
    }
    .right {
      width: 28%;
      margin-left: 10px;
      padding: 34px 24px 20px 24px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-decoration: underline;
          color: #666666;
          cursor: pointer;
        }
      }
      .rightTitle {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        margin-bottom: 35px;
      }
      .tabsBox {
        display: flex;
        justify-content: space-around;
        font-size: 16px;
        font-weight: bold;
        color: #666666;
        & > div {
          cursor: pointer;
        }
        .active {
          color: #1a1a1a;
          position: relative;
          &::after {
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            display: block;
            width: 20px;
            height: 3px;
            background: #2280ff;
          }
        }
      }
      .nowEdition {
        height: 64px;
        line-height: 64px;
        background: #f8f9fa;
        border: 1px solid #f2f3f5;
        padding-left: 15px;
        margin-bottom: 40px;
      }
      .editionList {
        .listItem {
          height: 70px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #f2f3f5;
          margin-bottom: 24px;
          span {
            font-size: 16px;
            font-weight: bold;
          }
          .editionName {
            position: relative;

            .isRecommend {
              width: 47px;
              height: 22px;
              position: absolute;
              top: -20px;
              right: -40px;
              font-size: 12px;
              display: flex;
              justify-content: center;
              background-size: 100% 100%;
              padding-top: 2px;
              img {
                width: 10px;
                height: 10px;
              }
              div {
                color: #fff;
                transform: scale(0.8, 0.8);
                letter-spacing: 1px;
              }
            }
          }
        }
        .active {
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #2280ff;
            border-left: 3px solid #2280ff;
          }
        }
      }
    }
    .payStyle {
      display: flex;
      height: 64px;
      justify-content: space-between;
      margin-bottom: 40px;
      & > div {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f8fa;
        border: 1px solid #dcdee0;
        user-select: none;
        img {
          margin-right: 10px;
        }
      }
      .active {
        background-color: #f3f7fd;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid #2280ff;
          background-image: var(--bgm);
          background-repeat: no-repeat;
          background-position: top right;
        }
      }
    }
    .showPrice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .payButton {
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      background: #1467ff;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

/deep/ .paymentDialog .el-dialog__header {
  text-align: left !important;
}
/deep/ .paymentDialog .el-dialog__body {
  padding: 25px 25px 0px;
}
.paymentDialog .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .qrcode {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .success {
      position: absolute;
      top: 0;
      left: 0;
      width: 132px;
      height: 132px;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 70px;
        font-weight: bold;
        color: #2fb72f;
      }
    }
  }
  .money {
    text-align: center;
    p:nth-child(1) {
      color: orange;
      font-size: 16px;
    }
    p:nth-child(2) {
      color: #868686;
      font-size: 12px;
      margin-top: 10px;
    }
  }
  .tips {
    color: #868686;
    font-size: 14px;
    line-height: 40px;
    margin-top: 20px;
    border-top: 1px dashed #e6e6e6;
    width: 100%;
    text-align: center;
  }
}
.infoDetail {
  .detailItem {
    margin: 10px 0;
    span:last-child {
      margin-left: 60px;
    }
  }
}
.timeList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    border: 1px solid #e4e4e4;
    line-height: 50px;
    flex: 1;
    text-align: center;
    user-select: none;
  }
  div:not(:last-child) {
    margin-right: 20px;
  }
  .active {
    border: 1px solid #1467ff;
  }
}
</style>
